import {
  SET_IS_LOADING,
  SET_COMISSIONED_LIST,
  SET_COMISSIONED_LIST_ALL,
  SET_COMISSIONED_FILTERS,
  CLEAR_COMISSIONED_LIST
} from './constants';

const defaultState = {
  filters: {},
  list: [],
  listAll: [],
  total: 0,
  isLoading: false
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading
      }
    }
    case SET_COMISSIONED_LIST: {
      return {
        ...state,
        list: action.data,
        total: action.total
      }
    }
    case SET_COMISSIONED_LIST_ALL: {
      return {
        ...state,
        listAll: action.data
      }
    }
    case SET_COMISSIONED_FILTERS: {
      return {
        ...state,
        filters: action.data
      }
    }
    case CLEAR_COMISSIONED_LIST: {
      return {
        ...state,
        list: [],
        total: 0
      }
    }
    default: {
      return state;
    }
  }
}