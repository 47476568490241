export const defaultSelects = [
  {
    label: 'Filtrar por data de:',
    type: 'select',
    id: 'typeDateTime',
    advanced: false,
    options: [
      { label: '--', value: '' },
      { label: 'Saída', value: 'exitDateTime' },
      { label: 'Entrada', value: 'entryDateTime' },
      { label: 'Pagamento', value: 'paymentDateTime' }
    ],
    value: ''
  },
  {
    advanced: false,
    type: 'input',
    id: 'card',
    label: 'Número do cartão',
    placeholder: '9999-9999-9999-9999',
    value: ''
  },
  {
    id: 'status',
    label: 'Situação',
    advanced: false,
    type: 'select',
    value: 1,
    options: [
      { label: 'Ativado', value: 1 },
      { label: 'Desativado', value: 2 }
    ]
  },
  {
    label: 'Situação',
    type: 'select',
    id: 'situation',
    advanced: false,
    options: [
      { label: '--', value: '' },
      { label: 'Aberta', value: 1 },
      { label: 'Encerrada', value: 2 }
    ],
    value: ''
  },
  {
    label: 'Situação da Fatura',
    type: 'select',
    id: 'clientInvoiceSituation',
    advanced: false,
    options: [
      { label: '--', value: '' },
      { label: 'Em Dia', value: 1 },
      { label: 'Atrasado', value: 2 },
      { label: 'Pagos no mês', value: 4 },
      { label: 'Cancelada', value: 3 }
    ],
    value: ''
  },
  {
    label: 'Situação da Nota Fiscal',
    type: 'select',
    id: 'nfseStatus',
    advanced: false,
    options: [
      { label: '--', value: '' },
      { label: 'Emitindo', value: 1 },
      { label: 'Emitida com sucesso', value: 2 },
      { label: 'Cancelada', value: 3 },
      { label: 'Erro na Emissão', value: 4 },
      { label: 'Cancelando', value: 5 },
      { label: 'Sincronizando', value: 6 }
    ],
    value: ''
  },
  {
    label: 'Tipo de restrição',
    type: 'select',
    id: 'restrictionType',
    advanced: false,
    options: {}
  }
]