import { compose, over, set, lensProp, map, assoc } from 'ramda';

import { formatDate, formatEmptyValue } from '../../../helpers/formatters';
import { convertToReal } from '../../../helpers/converters';

import {
  SET_RPS,
  SET_RPS_INFO,
  SET_IS_NF_INFO,
  SET_SOURCE_ID,
  SET_SELECTED_NF_WITH_ERRORS,
  SET_SELECTED_SINGLE_NF_WITH_ERRORS_PAYMENT_DATE_TIME,
  SET_SELECTED_SINGLE_NF_WITH_ERRORS,
  CLEAR_SELECTED_PAGE_NF_WITH_ERRORS,
  CLEAR_SELECTED_NF_WITH_ERRORS,
  SET_IS_NF_SEND_MODAL_OPEN,
  HANDLE_LOADING,
  HANDLE_ERROR,
  SET_IS_RESUME_LOADING,
  SET_RPS_RESUME
} from './constants';

const defaultState = {
  list: [],
  total: null,
  isLoading: false,
  isResumeLoading: false,
  error: null,
  transactionId: null,
  sourceId: null,
  establishmentId: null,
  paymentDateTime: null,
  selectedNfWithErrors: [],
  selectedNfWithErrorsPaymentDateTime: [],
  isSendNfModalOpen: false,
  isNfInfo: false,
  nfInfo: {
    rpsSeries: null,
    rpsNumber: null,
    nfNumber: null,
    nfStatus: null
  },
  resume: {
    success: 0,
    error: 0,
    amountSuccess: 0,
    amountError: 0
  }
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_RPS: {
      const formatRps = rps => {
        const {
          rpsSeries,
          rpsNumber,
          nfStatus,
          nfNumber,
          nfCancelProtocol,
          paymentDateTime,
          amount
        } = rps;

        const formatter = compose(
          assoc('rpsSerieAndNumber', `${rpsSeries}-${rpsNumber}`),
          assoc('nfseStatusNumber', nfStatus),
          assoc('nfseNumber', nfNumber),
          assoc('nfseStatus', nfStatus),
          assoc('nfCancelProtocol', nfCancelProtocol),
          over(lensProp('nfNumber'), formatEmptyValue),
          over(lensProp('nfDateTime'), formatDate),
          assoc('amountView', convertToReal(amount)),
          assoc('paymentDateTimeView', formatDate(paymentDateTime))
        );

        return formatter(rps);
      }

      const list = map(formatRps, action.data);

      const rps = compose(
        set(lensProp('list'), list),
        set(lensProp('total'), action.total),
        set(lensProp('isLoading'), false)
      );

      return rps(state);
    }
    case SET_RPS_INFO: {
      const {
        rpsSeries,
        rpsNumber,
        nfNumber,
        nfStatus
      } = action.data;

      return {
        ...state,
        nfInfo: {
          rpsSeries,
          rpsNumber,
          nfNumber,
          nfStatus
        }
      }
    }
    case SET_IS_NF_INFO: {
      return {
        ...state,
        isNfInfo: action.status
      }
    }
    case SET_SOURCE_ID: {
      return {
        ...state,
        sourceId: action.sourceId
      }
    }
    case SET_SELECTED_NF_WITH_ERRORS: {
      const { data } = action;

      return {
        ...state,
        selectedNfWithErrors: [...new Set([...state.selectedNfWithErrors, ...data])]
      }
    }
    case SET_SELECTED_SINGLE_NF_WITH_ERRORS_PAYMENT_DATE_TIME: {
      return {
        ...state,
        selectedNfWithErrorsPaymentDateTime: action?.data
      }
    }
    case SET_SELECTED_SINGLE_NF_WITH_ERRORS: {
      const { sourceId } = action;

      let list = state.selectedNfWithErrors;

      if(state.selectedNfWithErrors.includes(sourceId)) {
        list = list.filter(item => item != sourceId)

        return {
          ...state,
          selectedNfWithErrors: list
        }
      }

      return {
        ...state,
        selectedNfWithErrors: [...list, sourceId]
      }
    }
    case CLEAR_SELECTED_PAGE_NF_WITH_ERRORS: {
      const filteredNfs = state.selectedNfWithErrors?.filter(item => !action.data?.includes(item));

      return {
        ...state,
        selectedNfWithErrors: filteredNfs
      }
    }
    case CLEAR_SELECTED_NF_WITH_ERRORS: {
      return {
        ...state,
        selectedNfWithErrors: []
      }
    }
    case SET_IS_NF_SEND_MODAL_OPEN: {
      return {
        ...state,
        isSendNfModalOpen: action.status
      }
    }
    case HANDLE_LOADING: {
      return set(lensProp('isLoading'), action.status, state);
    }
    case HANDLE_ERROR: {
      return {
        ...state,
        error: action.error,
        transactionId: action.transactionId,
        establishmentId: action.establishmentId,
        paymentDateTime: action.paymentDateTime
      }
    }
    case SET_IS_RESUME_LOADING: {
      return {
        ...state,
        isResumeLoading: action.status
      }
    }
    case SET_RPS_RESUME: {
      if(!action?.data) {
        return {
          ...state,
          resume: {
            success: 0,
            error: 0,
            amountSuccess: 0,
            amountError: 0
          }
        }
      }

      const { success, error, amountSuccess, amountError } = action.data;

      return {
        ...state,
        resume: {
          success,
          error,
          amountSuccess,
          amountError
        }
      }
    }
    default: {
      return state;
    }
  }
}