import { newAPI } from '../../../services';
import qs from 'qs';

export const fetchProvisionalReceipt = async url => {
  const response = await newAPI.get(url);
  return response.data;
}

export const sendNewNfse = async (url, params) => {
  const response = await newAPI.put(url, qs.stringify(params));
  return response.data;
}

export const sendMultipleNfse = async (userId, establishmentId, sourceIds) => {
  const response = await newAPI.post(`${userId}/establishment/${establishmentId}/nf/nfse/send_ids`, qs.stringify({ sourceIds }));
  return response.data;
}

export const cancelNfse = async (userId, establishmentId, electronicInvoicesUuid) => {
  const response = await newAPI.put(`${userId}/establishment/${establishmentId}/nf/nfse/${electronicInvoicesUuid}/cancel`);
  return response.data;
}

export const fetchNfseConcept = async (userId, establishmentId, sourceId) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/nf/nfse/${sourceId}/consult`);
  return response.data;
}

export const fetchDownloadNfse = async (userId, establishmentId, sourceId) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/nf/nfse/${sourceId}/download/pdf`, { responseType: 'blob' });
  return response.data;
}

export const fetchProvisionalReceiptResume = async (userId, establishmentId, qp, extraProps) => {
  const { startDate, endDate } = extraProps;

  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/nf/nfse/usage-report?startDate=${startDate}&endDate=${endDate}${qp}`);
  return response.data;
}