export const inputs = [
  {
    advanced: false,
    type: 'input',
    id: 'name',
    label: 'Empresa',
    value: ''
  },
  {
    advanced: false,
    type: 'input',
    id: 'cnpj',
    label: 'CNPJ',
    value: ''
  },
  {
    advanced: false,
    type: 'input',
    id: 'email',
    label: 'Email',
    value: ''
  },
  {
    advanced: false,
    type: 'input',
    id: 'phone',
    label: 'Telefone',
    value: ''
  },
  {
    advanced: false,
    type: 'input',
    id: 'contactName',
    label: 'Responsável',
    value: ''
  }
];