export const RESTRICTION_PAGE = {
  CREATE_ESTABLISHMENT: 18,
  EDIT_ESTABLISHMENT: 19,
  VIEW_ESTABLISHMENT: 90,
  CREATE_USER: 24,
  EDIT_USER: 25,
  VIEW_USER: 23
}

export const SITE_RESTRICTIONS = {
  BUSINESS: 102,
  CLIENT: 103,
  CONFIG: 111,
  PROVISIONAL_RECEIPT: 104,
  REMOVE_OS: 107,
  EDIT_OS: 108,
  SINGLE_TRANSACTIONS: 109,
  CASH_DRAIN: 110,
  EDIT_CLIENT: 105,
  CREATE_CLIENT: 106,
  REMOVE_TRANSACTION_ATTACHMENT_AND_CASHIER: 112,
  CLIENT_AREA: 113,
  COMISSION: 114,
  PAYROLL_SHEET: 115,
  SESSION_REGISTER: 116,
  FINANCIAL: 117,
  CLIENTS: 118
}

export const SITE_RESTRICTIONS_TO_PAGES = [
  { page: '/cobranca/boletos', restriction: SITE_RESTRICTIONS.CLIENT_AREA },
  { page: '/comissionamento', restriction: SITE_RESTRICTIONS.COMISSION },
  { page: '/funcionarios', restriction: SITE_RESTRICTIONS.PAYROLL_SHEET },
  { page: '/registro-de-sessoes', restriction: SITE_RESTRICTIONS.SESSION_REGISTER }
];