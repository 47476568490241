import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { compose, map, pick, prop, values, sum } from 'ramda';
import { Document, Page, View, Text } from '@react-pdf/renderer';

import Header from '../Header';
import Footer from '../Footer';
import { styles } from '../styles';

import { convertToBrl, convertFormattedMoneyToNumber } from '../../../../helpers/converters';

const Table = ({ data, headers }) => {
  const items = map(values, data);
  const tableWidth = 100 / headers.length;

  return(
    <View style={[styles.table, { marginBottom: 0 }]}>
      <View style={styles.tableRow}>
        {headers.map(header => (
          <View style={[styles.tableColHeader, { width: `${tableWidth}%` }]}>
            <Text style={styles.tableCellHeader}>{header.label}</Text>
          </View>
        ))}
      </View>
      {items.map(item => (
        <View style={styles.tableRow}>
          {item.map(cell => (
            <View style={[styles.tableCol, { width: `${tableWidth}%` }]}>
              <Text style={styles.tableCell}>{cell}</Text>
            </View>
          ))}
        </View>
      ))}
    </View>
  );
}

const List = ({ exportType, exportData, exportHeaders, exportResume, dateRange, fileName, availablePaymentMethods, establishmentName }) => {
  const searchIds = map(label => prop('id', label), exportHeaders);
  const data = map(pick(searchIds), exportData);
  const orientation = exportHeaders.length > 5 ? 'landscape' : 'portrait';

  let formattedData = data?.map(content => {
    return {
      ...content,
      vacancies: content?.vacancies || '-',
      vehicles: content?.vehicles?.join(", ")?.toString() || '-'
    }
  });

  if(exportType == 'extract') {
    formattedData = data?.map(content => {
      return {
        orderDateTime: moment(content?.orderDateTime, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss'),
        category: content?.category,
        description: content?.description,
        amount: convertToBrl(convertFormattedMoneyToNumber(content?.amount)),
        paymentMethodId: availablePaymentMethods?.find(paymentMethod => +paymentMethod?.value == +content?.paymentMethodId)?.label
      }
    });
  }

  const amount = exportResume
    ? compose(
        sum,
        values,
        pick([
          'totalAmountSoRotary',
          'totalAmountSoRecurrent',
          'totalAmountSoCovenant'
        ])
      )(exportResume)
    : null;

  const totalPeriod = exportResume && exportResume.totalPeriod;

  return(
    <Document
      title={establishmentName == 'Todos os estabelecimentos' ? '' : `${establishmentName}-clientes.pdf`}
    >
      <Page orientation={orientation} style={styles.body}>
        <Header
          fileName={fileName}
          dateRange={dateRange}
          establishmentName={establishmentName}
        />
        <Table
          headers={exportHeaders}
          data={formattedData}
        />
        <Footer
          data={formattedData}
          amount={amount}
          totalPeriod={totalPeriod}
          total={data.length}
          style={styles.borderLeft}
        />
      </Page>
    </Document>
  );
}

List.propTypes = {
  exportData: PropTypes.arrayOf(PropTypes.shape.isRequired).isRequired,
  exportHeaders: PropTypes.arrayOf(PropTypes.shape.isRequired).isRequired,
  exportResume: PropTypes.shape.isRequired,
  dateRange: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  fileName: PropTypes.string.isRequired,
  establishmentName: PropTypes.string.isRequired
};

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape.isRequired).isRequired,
  headers: PropTypes.arrayOf(PropTypes.shape.isRequired).isRequired
};

export default List;