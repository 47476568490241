import React from 'react';

import { Typography } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

export default function WarningMessage({
  message,
  fontColor,
  textAlign = 'center',
  iconColor,
  iconSize = 'lg',
  justifyContent = 'center'
}) {
  const getIconColor = () => {
    if(iconColor == 'error') {
      return '#E53935';
    }

    return '#F5B941';
  }

  return(
    <div style={{ display: 'flex', justifyContent, alignItems: 'center', gap: 20 }}>
      <div style={{ padding: 5 }}>
        <FontAwesomeIcon
          icon={faExclamationCircle}
          color={getIconColor()}
          size={iconSize}
        />
      </div>
      <div style={{ textAlign }}>
        <Typography color={fontColor ? fontColor : 'textSecondary'}>
          {message}
        </Typography>
      </div>
    </div>
  );
}