import * as type from './constants';

export const setIsLoading = isLoading => ({
  type: type.SET_IS_LOADING,
  isLoading
});

export const setComissionedList = (data, total) => ({
  type: type.SET_COMISSIONED_LIST,
  data,
  total
});

export const setComissionedListAll = data => ({
  type: type.SET_COMISSIONED_LIST_ALL,
  data
});

export const setComissionedFilters = data => ({
  type: type.SET_COMISSIONED_FILTERS,
  data
});

export const clearComissionedList = () => ({
  type: type.CLEAR_COMISSIONED_LIST
});