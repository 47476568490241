import React from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Loader from 'components/Loader';

import { formatCurrency } from 'helpers/converters';

const Resume = ({ resume, isLoading }) => {
  return(
    <Grid container spacing={2} style={{ marginBottom: 10 }}>
      <Grid item xs={12} sm={6} lg={3}>
        <Paper style={{ borderBottom: '5px solid #13BC4B', padding: '10px 0 10px 0', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <Loader isLoading={isLoading}>
            <Typography variant="subtitle1" color="primary" align="center">
              Quantidade notas emitidas
            </Typography>
            <Typography variant="h4" color="primary" align="center">
              {resume.success}
            </Typography>
          </Loader>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Paper style={{ borderBottom: '5px solid #13BC4B', padding: '10px 0 10px 0', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <Loader isLoading={isLoading}>
            <Typography variant="subtitle1" color="primary" align="center">
              Valor de notas emitidas
            </Typography>
            <Typography variant="h4" color="primary" align="center">
              {formatCurrency(resume.amountSuccess)}
            </Typography>
          </Loader>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Paper style={{ borderBottom: '5px solid #F66C6B', padding: '10px 0 10px 0', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <Loader isLoading={isLoading}>
            <Typography variant="subtitle1" color="primary" align="center">
              Valor de notas não emitidas
            </Typography>
            <Typography variant="h4" color="primary" align="center">
              {formatCurrency(resume.amountError)}
            </Typography>
          </Loader>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Paper style={{ borderBottom: '5px solid #F66C6B', padding: '10px 0 10px 0', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <Loader isLoading={isLoading}>
            <Typography variant="subtitle1" color="primary" align="center">
              Quantidade notas não emitidas
            </Typography>
            <Typography variant="h4" color="primary" align="center">
              {resume.error}
            </Typography>
          </Loader>
        </Paper>
      </Grid>
    </Grid>
  );
}

Resume.defaultProps = {
  resume: null
};

Resume.propTypes = {
  resume: PropTypes.shape({
    success: PropTypes.number,
    canceled: PropTypes.number,
    pending: PropTypes.number,
    error: PropTypes.number
  })
};

export default Resume;