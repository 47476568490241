import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { map, range } from 'ramda';

import { Formik, Form } from 'formik';
import { Paper, Grid, Typography } from '@material-ui/core';
import Loader from 'components/Loader';
import Input from 'components/Input';
import Select from 'components/Select';
import Button from 'components/Button';

import { formatAvailableEstablishments } from 'helpers/formatters';
import { setComissionedPayroll, clearComissionedPayroll, clearComissionedPayrollErrors } from '../store/actions';
import { loadComissionedPayroll, upsertComissionedPayroll } from '../store/thunk';
import { loadComissionedFilters, loadComissionedAllList } from 'pages/ComissionedList/store/thunk';

import { comissionedPayrollRegistrationValidations } from './validations';

import { useStyles } from './styles';

export default function ComissionedPayrollRegistration() {
  const dispatch = useDispatch();

  const classes = useStyles();

  const history = useHistory();
  const isRegistration = history.location.pathname == '/novo-comissionado';
  const { uuid } = history?.location?.state?.data || '';

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { profileId } = useSelector(state => state.profile.submit);
  const { isLoading, submit, comissionedPayrollErrors } = useSelector(state => state.comissionedPayrollRegistration);
  const establishments = useSelector(state => state.login.establishments);

  const isUserAdmin = +profileId == 1;

  useEffect(() => {
    dispatch(clearComissionedPayrollErrors());
  }, []);

  useEffect(() => {
    if(isRegistration) {
      dispatch(clearComissionedPayroll());
    }
  }, [isRegistration]);

  useEffect(() => {
    if(establishmentId && uuid) {
      dispatch(loadComissionedPayroll(userId, establishmentId, uuid));
    }
  }, [establishmentId, uuid]);

  useEffect(() => {
    dispatch(loadComissionedFilters({ userId }));
    dispatch(loadComissionedAllList({ userId, establishmentId }));
  }, []);

  const commissionProfiles = useSelector(state => state.comissionedList.filters.commissionProfiles)?.options?.map(profile => {
    return {
      label: profile?.name,
      value: profile?.id
    }
  });

  const comissionedNames = useSelector(state => state.comissionedList.listAll)?.map(comissioned => {
    return {
      label: comissioned?.name,
      value: comissioned?.uuid
    }
  });

  const availableEstablishments = formatAvailableEstablishments(establishments);

  const formattedAvailableEstablishments = availableEstablishments
    ?.map(establishment => {
      return {
        ...establishment,
        label: isUserAdmin ? `${establishment.establishmentId} - ${establishment.label}` : establishment.label,
        value: establishment?.uuid
      }
    })
    ?.filter(establishment => +establishment?.premiumPackage == 1);

  const monthDays = map(day => ({ id: 'invoiceDueDay', label: day, value: day }), range(0, 30));

  const profileDescription = profileId => {
    switch(profileId) {
      case 1: {
        return <Typography>O gerente recebe comissão de todas as Ordens de Serviços geradas daquele Estabelecimento que ele está vinculado. A Diferença entre Diretor e Gerente podem ser os valores de comissionamento.</Typography>;
      }
      case 2: {
        return <Typography>Perfil que recebe comissionamento ao ser vinculado individualmente em cada serviço. O mesmo serviço pode ter diversos lavadores.</Typography>;
      }
      case 3: {
        return <Typography>O vendedor é vinculado na Ordem de Serviço e ele ganha comissão dos serviços que possuem configuração de comissão vinculados especificamente na Ordem de serviço.</Typography>;
      }
      case 4: {
        return <Typography>O gerente recebe comissão de todas as Ordens de Serviços geradas daquele Estabelecimento que ele está vinculado. A Diferença entre Diretor e Gerente podem ser os valores de comissionamento.</Typography>;
      }
      default: {
        return '';
      }
    }
  }

  const handleSubmit = values => {
    if(isRegistration) {
      dispatch(setComissionedPayroll(values));
    }

    const params = {
      ...values,
      establishments: values.establishments?.length > 0
        ? values.establishments?.filter(establishment => !!establishment)
        : ['']
    }

    dispatch(upsertComissionedPayroll(userId, establishmentId, uuid, params, history));
  }

  return(
    <Paper className={classes.paper}>
      <Loader isLoading={isLoading}>
        <Formik
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={{
            ...submit,
            establishments: (isRegistration && formattedAvailableEstablishments?.length == 1) ? [formattedAvailableEstablishments[0]?.uuid] : submit?.establishments
          }}
          validateOnBlur={false}
          validateOnChange={false}
          validateOnMount={false}
          validationSchema={comissionedPayrollRegistrationValidations}
        >
          {({ ...formikProps }) => {
            return(
              <Form>
                <Grid container>
                  {isRegistration && (
                    <Grid item xs={12} className={classes.grid}>
                      <Grid className={classes.infoCard}>
                        <Typography className={classes.infoCardText}>
                          Atenção: <br />
                          Crie um funcionário antes de criar um comissionado.
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={12} md={6} className={classes.grid}>
                    <Grid container>
                      <Grid item xs={12}>
                        {isRegistration ? (
                          <Select
                            name='employeeId'
                            label='Vincular funcionário'
                            options={comissionedNames}
                            value={formikProps?.values?.employeeId}
                            error={!!comissionedPayrollErrors?.employeeError}
                            helperText={comissionedPayrollErrors?.employeeError}
                            onChange={formikProps?.handleChange}
                          />
                        ) : (
                          <Input
                            disabled
                            name='employeeName'
                            label='Vincular funcionário'
                            value={formikProps?.values?.name}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Select
                          multiple
                          name='establishments'
                          label='Vincular estabelecimentos'
                          options={formattedAvailableEstablishments}
                          value={formikProps?.values?.establishments?.filter(establishment => !!establishment) || []}
                          error={formikProps?.errors?.establishments || !!comissionedPayrollErrors?.establishmentsError}
                          helperText={formikProps?.errors?.establishments || comissionedPayrollErrors?.establishmentsError}
                          onChange={formikProps?.handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Select
                          name='dueDays'
                          label='Após fechar comissão vencer em X dias'
                          options={monthDays}
                          value={formikProps?.values?.dueDays}
                          error={!!comissionedPayrollErrors?.dueDaysError}
                          helperText={comissionedPayrollErrors?.dueDaysError}
                          onChange={formikProps?.handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6} className={classes.grid}>
                    <Select
                      name='profileId'
                      label='Perfil'
                      options={commissionProfiles}
                      value={formikProps?.values?.profileId}
                      error={!!comissionedPayrollErrors?.profileError}
                      helperText={comissionedPayrollErrors?.profileError}
                      onChange={formikProps?.handleChange}
                    />
                    <Typography
                      color='primary'
                      style={{ marginTop: 10 }}
                    >
                      {profileDescription(formikProps?.values?.profileId)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.buttonGrid}>
                    <Button type='submit' color='success'>
                      Salvar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Loader>
    </Paper>
  );
}