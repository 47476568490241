import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Grid, Paper } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Filter from 'components/Filter/components';
import FilterMobile from 'components/FilterMobile/components';
import MTable from 'components/MTable';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';

import { loadSupplierList, loadSupplierExport } from '../store/thunk';
import { clearSupplier } from '../../SupplierRegistration/store/actions';

import { inputs } from './resources';

import useDidMount from 'helpers/hooks/useDidMount';

const headers = [
  { title: 'Responsável', field: 'name' },
  { title: 'Empresa', field: 'corporateName' },
  { title: 'CNPJ', field: 'cnpj' },
  { title: 'Telefone', field: 'phone' }
];

const exportHeaders = [
  { id: 'name', label: 'Responsável' },
  { id: 'corporateName', label: 'Empresa' },
  { id: 'cnpj', label: 'CNPJ' },
  { id: 'phone', label: 'Telefone' },
  { id: 'address', label: 'Endereço' }
];

export default function SupplierList() {
  const dispatch = useDispatch();

  const history = useHistory();

  const didComponentMount = useDidMount();

  const width = window.innerWidth;
  const isMobile = width < 1024;

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { isLoading, total, list } = useSelector(state => state.supplierList);
  const { qp } = useSelector(state => state.filters);

  const [supplierPage, setSupplierPage] = useState(1);
  const supplierPageSize = 10;
  const supplierPageCount = Math.ceil(total / supplierPageSize);

  const handleLoadSupplierExport = () => {
    return dispatch(loadSupplierExport(userId, establishmentId, qp));
  }

  const requestSupplierList = ({ establishmentId, page, qp }) => {
    dispatch(loadSupplierList(userId, establishmentId, page, qp));
  }

  useEffect(() => {
    if(didComponentMount) {
      requestSupplierList({ establishmentId, page: supplierPage, qp });
    }
  }, []);

  useEffect(() => {
    if(establishmentId && !didComponentMount) {
      requestSupplierList({ establishmentId, page: supplierPage, qp });
    }
  }, [establishmentId]);

  useEffect(() => {
    if(!didComponentMount) {
      requestSupplierList({ establishmentId, page: supplierPage, qp });
    }
  }, [supplierPage]);

  return(
    <Paper>
      {isMobile ? (
        <FilterMobile
          insertSupplierButtons
          handleSupplierButtons={() => dispatch(clearSupplier())}
          alternativeExport
          functionAlternativeExport={handleLoadSupplierExport}
          exportType='supplier'
          exportHeaders={exportHeaders}
          filter
          filters={inputs}
          handlePageRequest={({ qp }) => {
            setSupplierPage(1);
            return requestSupplierList({ establishmentId, page: 1, qp });
          }}
        />
      ) : (
        <Filter
          insertSupplierButtons
          handleSupplierButtons={() => dispatch(clearSupplier())}
          alternativeExport
          functionAlternativeExport={handleLoadSupplierExport}
          exportType='supplier'
          exportHeaders={exportHeaders}
          filter
          filters={inputs}
          handlePageRequest={({ qp }) => {
            setSupplierPage(1);
            return requestSupplierList({ establishmentId, page: 1, qp });
          }}
        />
      )}
      <MTable
        loading={isLoading}
        headers={headers}
        data={list}
        actions={[
          rowData => ({
            icon: () => (
              <FontAwesomeIcon
                icon={faPen}
                color="#2CA9D6"
                size="xs"
              />
            ),
            tooltip: 'Editar Fornecedor',
            onClick: (_, rowData) => {
              const { uuid } = rowData;

              return history.push({
                pathname: '/editar-fornecedor',
                state: {
                  data: {
                    uuid
                  }
                }
              });
            }
          })
        ]}
      />
      <Grid
        container
        xs={12}
        style={{ padding: 10, display: 'flex', justifyContent: 'flex-end' }}
      >
        <Pagination
          color="primary"
          variant="outlined"
          shape="rounded"
          count={supplierPageCount}
          page={supplierPage}
          onChange={(_, page) => setSupplierPage(page)}
        />
      </Grid>
    </Paper>
  );
}